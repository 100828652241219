<template>
    <v-row>
        <v-col cols="12" md="12">
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col class="px-1 px-lg-3">
                                <InputDatePicker
                                    type="month"
                                    :disabled="carregando"
                                    v-model="busca.dtmes"
                                    label="Data"
                                    :outlined="true"
                                    :dense="true"
                                    @change="listar()"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card class="pa-3">Tela de hora extra aguardando confirmação de requisitos</v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
//import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";

export default {
    name: "DepartamentoPessoalHoraExtra",
    components: { InputDatePicker },
    data: () => ({
        carregando: false,
        busca: {
            dtmes: `${new Date().getFullYear()}-${
                (new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    0
                ).getMonth() +
                    1 <
                10
                    ? "0"
                    : "") +
                (new Date().getMonth() + 1)
            }`, // +1
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
};
</script>